<template>
  <div class="container">
    <el-card class="item" v-for="(i, n) in financialData" :key="n">
      <div class="data">
        <bm-tween
          class="number"
          :style="{ color: $store.getters['global/randomColor']() }"
          :value="i.number"
        ></bm-tween>
        <div class="text">{{ i.text }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    financialData: Array
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  width: 100%;

  .item {
    .pointer;
    .border;
    .flexCenter;
    border-radius: 10px;
    width: 25%;
    height: 130px;
    margin: 20px;
    .number {
      font-size: 28px;
    }
    .data {
      text-align: center;
    }
  }
}
</style>
