<route>
{
  "meta": {
    "auth": "vendorFinancial"
  }
}
</route>

<template>
  <div>
    <div class="financial-data">
      <comFinancialData :financial-data="financialData"></comFinancialData>
    </div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商家昵称</span>
          <el-input
            class="searchInput"
            placeholder="请输入商家昵称"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.businessName"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="12">
          <span class="searchText">提交时间</span>
          <el-date-picker
            v-model="date"
            type="datetimerange"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="请输入开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商家ID</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入商家ID"
            @keyup.enter.native="getList"
            v-model="searchValue.businessId"
            clearable
          >
          </el-input>
        </el-col>
        <el-form inline>
          <bm-areaSelect v-model="areaId"></bm-areaSelect>
        </el-form>
        <el-col :span="8">
          <el-button type="info" @click="resetSearch">重置</el-button>
          <el-button type="primary" @click="getList"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="businessId" label="商家ID"> </el-table-column>
        <el-table-column align="center" label="商家信息">
          <template slot-scope="scope">
            <div class="product-info">
              <img class="avatar" :src="scope.row.businessLogo | thumbSmall" />

              <span class="title"> {{ scope.row.businessName }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="areaId" label="地区">
          <template slot-scope="scope">
            {{ scope.row.areaId | formatArea }}
          </template>
        </el-table-column> -->
        <el-table-column align="center" prop="checkOffMoney" label="待核销金额"> </el-table-column>
        <el-table-column align="center" prop="checkOffCount" label="待核销数量"> </el-table-column>
        <el-table-column align="center" prop="" label="已核销金额">
          <template slot-scope="scope">
            {{ scope.row.checkNoMoney | twoPoint }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="checkNoCount" label="已核销笔数"> </el-table-column>
        <el-table-column align="center" prop="haveWithdrawMoney" label="已提现金额(元)"> </el-table-column>
        <el-table-column align="center" prop="canWithdrawMoney" label="待提取金额(元)"> </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-dropdown @command="handleDownload($event, scope.row)" style="margin-right: 5px">
              <el-button type="text" size="small">
                导出
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="orderByBusinessExport">核销记录</el-dropdown-item>
                <el-dropdown-item command="productSell">售出记录</el-dropdown-item>
                <el-dropdown-item command="allStatusOrderExport">订单记录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button @click="check(scope.row, 'writeoff')" type="text" size="small"> 核销记录</el-button>
            <el-button @click="check(scope.row, 'withdrawal')" type="text" size="small"> 提现记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      :title="download.title"
      :visible.sync="download.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 80px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div style="margin-top: 10px" v-if="download.showStatus">
        <span style="display: inline-block; width: 80px">订单状态</span>
        <el-select class="searchInput" v-model="download.status" placeholder="请选择订单状态">
          <el-option label="不可核销" value="0"> </el-option>
          <el-option label="未核销" value="1"> </el-option>
          <el-option label="已核销" value="2"> </el-option>
          <el-option label="已过期" value="3"> </el-option>
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import comFinancialData from './__com__/financial'
export default {
  mixins: [pagination],
  components: {
    comFinancialData
  },
  data() {
    return {
      date: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      searchValue: {
        businessId: '',
        businessName: '',
        endTime: 0,
        startTime: 0
      },
      download: {
        id: '',
        name: '',
        show: false,
        url: '',
        title: '',
        time: [],
        showStatus: false,
        status: ''
      },
      areaId: '',
      businessDistrictId: '',
      financialData: [
        {
          number: 0,
          text: '交易总金额',
          value: 'totalActualPayMoney'
        },
        {
          number: 0,
          text: '待核销金额',
          value: 'totalWriteNoMoney'
        },
        {
          number: 0,
          text: '已核销金额',
          value: 'totalWriteOffMoney'
        },
        {
          number: 0,
          text: '平台收入',
          value: 'platformProfit'
        }
      ],
      list: []
    }
  },
  methods: {
    confirmDownload() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      let params = this.download.showStatus ? { status: this.download.status } : {}
      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : '',
            endTime: this.download.time ? this.download.time[1] : '',
            businessId: this.download.id,
            businessName: this.download.name,
            ...params
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    handleDownload(command, row) {
      if (command === 'orderByBusinessExport') {
        this.download = {
          id: row.businessId,
          name: row.businessName,
          show: true,
          url: '/boom-center-statistics-service/sysAdmin/exportExl/orderByBusinessExport',
          title: '商家已核销订单',
          time: [],
          showStatus: false
        }
      } else if (command === 'productSell') {
        this.download = {
          id: row.businessId,
          name: row.businessName,
          show: true,
          url: '/boom-center-statistics-service/sysAdmin/exportExl/productSell',
          title: '商品销售记录表',
          time: [],
          showStatus: false
        }
      } else {
        this.download = {
          id: row.businessId,
          name: row.businessName,
          show: true,
          url: '/boom-center-statistics-service/sysAdmin/exportExl/allStatusOrderExport',
          title: '订单导出表',
          time: [],
          showStatus: true
        }
      }
    },
    check(row, path) {
      this.$router.push({
        path: '/financial/vendor/' + path,
        query: {
          id: row.businessId,
          subMchId: row.subMchid
        }
      })
    },
    getList(val) {
      this.loading = true
      this.searchValue.endTime = undefined
      this.searchValue.startTime = undefined
      if (this.date && this.date.length !== 0) {
        this.searchValue.endTime = this.date[1]
        this.searchValue.startTime = this.date[0]
      }
      this.currentPage = val === true ? this.currentPage : 1
      this.$api.financial
        .financial('getFinance', {
          params: {
            page: this.currentPage,
            size: this.pageSize,
            ...this.searchValue
          },
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.financialData.forEach(item => {
            item.number = res[item.value]
          })
          this.list = res.financeDetails.records
          this.total = parseInt(res.financeDetails.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    resetSearch() {
      this.searchValue = {
        businessId: '',
        businessName: '',
        endTime: 0,
        startTime: 0
      }
      this.date = [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}
.product-info {
  display: flex;
  align-items: center;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .title {
    vertical-align: middle;
  }
}
</style>
